import React, {Component} from 'react';
import {graphql, StaticQuery} from 'gatsby';
import CastCustomCollapse from "../CastAndCreativePage/CastCustomCollapse"
import {media} from "utils/Media"
import classnames from 'classnames';
import styled from "styled-components";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

const NavStyled = styled(Nav)`
  width: 100%;
  text-align: center;
  position: relative;
  transform: none;
  bottom: initial;
  left: initial;
  top: initial;
  background: none;
  z-index: 2;
  border: none !important;
  background: ${props => props.theme.colors.black};
  display: block;
  margin: 0 auto;
  padding: 2rem 0 1rem;
  
  @media ${media.md} {
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  h2 {
    color: ${props => props.theme.colors.white};
  }

  .nav-link {
    line-height: 100%;
    color: ${props => props.theme.colors.white};
    text-decoration: none;

    &.active {
      background: none;
      border: none;
      color: ${props => props.theme.colors.yellow};
    }
  }

  .lines {
    position: absolute;
    z-index: 1;
    background-color: ${props => props.theme.colors.red};
    height: 2px;
    width: 100vw;
    top: 10px;
    transition: all .4s ease;
    left: 0;

    @media ${media.md} {
      top: 48%;
    }

    @media ${media.xl} {
      bottom: 45px;
      top: initial;
    }

    &:after,
    &:before {
      content: "";
      position: absolute;
      right: 0;
      z-index: 9;
      background-color: ${props => props.theme.colors.orange};
      height: 2px;
      width: 98%;
      top: .4rem;
    }

    &:before {
      width: 96%;
      top: .8rem;
      background-color: ${props => props.theme.colors.yellow};
    }
  }

  li {
    position: relative;
    z-index: 2;

    a {
      cursor: pointer;
      color: ${props => props.theme.colors.yellow};
      text-transform: uppercase;
      margin: 0;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: none;
      border: none;
      font-size: 2rem !important;
      text-decoration: none !important;
      text-transform: uppercase;
      color: ${props => props.theme.colors.primary} !important;
      font-family: ${props => props.theme.font.family.boldTall} !important;
      letter-spacing: 0.02em;
      padding: 0 ;

      @media ${media.md} {
        font-size: 3.5rem !important;
        padding: 0.5rem 1rem;
      }

      @media ${media.lg} {
        font-size: 5rem !important;
      }

      @media ${media.xxl} {
        font-size: 6.25rem !important;
      }

      &.active {
        border: none;
        background: none !important;
      }

      &.active *{
        color: ${props => props.theme.colors.secondary};
      }
      
      &:hover {
        border-color: transparent !important;
      }
    }
  }

`

const Content = styled.div`
  text-align: center;
`

class CastBlock extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      activeId: "",
      activeTab: '1',
    }
  }
  
  toggleModal = (e, person) => {
    e && e.stopPropagation()
    e && e.preventDefault()
    this.setState({
      modalOpen: !this.state.modalOpen,
      person: person
    })
  }
  
  
  toggle = (tab, e) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  
  
  render() {
    return (
      <Content className="mb-3 mb-md-5">
        <NavStyled tabs className="c-collapse-nav">
          <NavItem
            className={classnames({active: this.state.activeTab === '1'})}
          >
            <NavLink
              className={classnames({active: this.state.activeTab === '1'})}
              onClick={(e) => {
                this.toggle('1', e);
              }}
            >
              <h2 className="h1 py-1 py-md-4 mb-0">Cast</h2>
            </NavLink>
          </NavItem>
          <NavItem
            className={classnames({active: this.state.activeTab === '2'})}
          >
            <NavLink
              className={classnames({active: this.state.activeTab === '2'})}
              onClick={(e) => {
                this.toggle('2', e);
              }}
            >
              <h2 className="h1 py-1 py-md-4 mb-0">Creatives</h2>
            </NavLink>
          
          </NavItem>
          <NavItem
            className={classnames({active: this.state.activeTab === '3'})}
          >
            <NavLink
              className={classnames({active: this.state.activeTab === '3'})}
              onClick={(e) => {
                this.toggle('3', e);
              }}
            >
              <h2 className="h1 py-1 py-md-4 mb-0">Band</h2>
            </NavLink>
          
          </NavItem>
          <NavItem
            className={classnames({active: this.state.activeTab === '4'})}
          >
            <NavLink
              className={classnames({active: this.state.activeTab === '4'})}
              onClick={(e) => {
                this.toggle('4', e);
              }}
            >
              <h2 className="h1 py-1 py-md-4 mb-0">Crew</h2>
            </NavLink>
          
          </NavItem>
          <div className="lines"/>
        </NavStyled>
        <TabContent className="pt-3 pt-md-5" activeTab={this.state.activeTab}>
          <TabPane tabId="1" key={`cast`}>
            <CastCustomCollapse toggleModal={this.toggleModal} data={this.props.data.allMaincastJson.edges}
                                classNames="cast" type="maincast"/>
            <CastCustomCollapse toggleModal={this.toggleModal} data={this.props.data.allCastJson.edges}
                                classNames="cast" type="cast"/>
          </TabPane>
          <TabPane tabId="2" key={`creative`}>
            <CastCustomCollapse toggleModal={this.toggleModal} data={this.props.data.allCreativesJson.edges}
                                classNames="creative" type="creative"/>
          </TabPane>
          <TabPane tabId="3" key={`band`}>
            <CastCustomCollapse toggleModal={this.toggleModal} data={this.props.data.allBandJson.edges}
                                classNames="creative" type="creative"/>
          </TabPane>
          <TabPane tabId="4" key={`crew`}>
            <CastCustomCollapse toggleModal={this.toggleModal} data={this.props.data.allCrewJson.edges}
                                classNames="creative" type="creative"/>
          </TabPane>
        </TabContent>
      </Content>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
            query {
               allMaincastJson {
                    edges {
                        node {
                            name
                            role
                            bioHTML
                            image {
                                childImageSharp {
                                     gatsbyImageData(width: 400)
                                }
                            }
                        }
                    }
                }
                allCastJson {
                    edges {
                        node {
                            name
                            role
                            bioHTML
                            image {
                                childImageSharp {
                                    gatsbyImageData(width: 400)
                                }
                            }
                        }
                    }
                }
                allCreativesJson {
                    edges {
                        node {
                            name
                            role
                            bioHTML
                        }
                    }
                }
                allBandJson {
                    edges {
                        node {
                            name
                            role
                        }
                    }
                }
                allCrewJson {
                    edges {
                        node {
                            name
                            role
                        }
                    }
                }
            }`
    }
    render={data => (
      <CastBlock data={data} {...props}/>
    )}
  />
)