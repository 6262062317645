import React, {Component} from "react"
import {Container, Row, Col} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import iconArrow from "images/icons/btn-arrows.svg"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

const CollapseContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin: 0 auto;
  text-align: left;
  .no-bio {
    pointer-events: none;
  }
  @media ${media.sm} {
    text-align: center;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }
`

const CollapseItem = styled.div`
  width: 100%;
  padding: 0.25rem 0;

  @media ${media.sm} {
    max-width: 400px;
  }

  @media ${media.md} {
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    width: 50%;
  }

  @media ${media.lg} {
    width: 33.3%;
    padding-bottom: 2rem;
  }

  .arrowDown {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    background: url("${iconArrow}") no-repeat center center;
    background-size: 100%;
    transition: transform 0.2s linear;
  }

  &.active {
    .arrowDown {
      transform: translateY(-50%) rotate(180deg);
    }
  }
`

const CollapseItemTrigger = styled.div`
  position: relative;
  display: flex;
  background: linear-gradient(to bottom, ${props => props.theme.colors.red}, ${props => props.theme.colors.yellow});
  padding: 4px;

  @media ${media.md} {
    flex-wrap: wrap;
  }

  &.cast, &.creative {
    cursor: pointer;
  }

  .trigger-helper {
    background: ${props => props.theme.colors.white};
    padding: 6px;
    transition: background 0.2s linear, color 0.2s linear;
    width: 100%;

    &.cast {
      padding: 0;
    }
  }

  .trigger-helper-inner-border {
    background: linear-gradient(to bottom, #FF0000, #FFD200);
    padding: 6px;
    height: 100%;
  }

  .trigger-helper-inner-content {
    background: ${props => props.theme.colors.white};
    height: 100%;
    padding: 6px 20px 6px 0;
  }

  h4 {
    font-family: Proxima Nova W01 Black, Arial, sans-serif;
  }

  h5 {
    margin-bottom: 0;
  }

  &.creative:hover {
    text-decoration: none;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.black};

    @media ${media.sm} {
      background-color: ${props => props.theme.colors.black};
      color: ${props => props.theme.colors.white};
    }

    h4, h5 {
      color: ${props => props.theme.colors.black};
    }

    .trigger-helper {
      background: ${props => props.theme.colors.white};

      @media ${media.sm} {
        background: ${props => props.theme.colors.black};
      }
    }
  }
  
  &.cast:hover {
    h4, h5 {
      color: ${props => props.theme.colors.white};
    }
  }
`

const CollapseTriggerTitle = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-size: 1rem;
  min-height: 45px;
  padding-right: 40px;
  padding-left: 6px;
  display: flex;
  align-items: center;

  @media ${media.sm} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
  }
  @media ${media.md} {
    min-height: 92px;
  }

  &.cast {
    padding-left: 0;
  }
  
  .copy {
    @media ${media.sm} {
      padding: 0.5rem;
    }
  }

  .gatsby-image-wrapper {
    display: block;
    max-width: 100%;
    height: 100%;
    width: 60px;
    margin-right: 6px;

    @media ${media.sm} {
      width: 100%;
      margin-right: 0;
    }
  }
`

const CollapseItemContent = styled.div`
  height: 0;
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  flex-wrap: wrap;
  justify-content: center;
  transition: height .3s ease;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  width: calc(100% - 30px);
  margin: 2px 0 2rem;

  @media ${media.sm} {
    width: 100%;
    margin: 2px auto 2rem;
  }

  .active & {
    visibility: visible;
  }
`

const CollapseItemInner = styled.div`
  text-align: center;
  padding: 1rem 1rem 6rem;

  @media ${media.sm} {
    padding: 3rem 1.5rem 6rem;
  }

  .collapse-content {
    margin: 0 auto;
    max-width: 800px;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CollapseClose = styled.button`
  background-color: transparent;
  border: 0;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  height: 60px;
  width: 60px;
  transform: translateX(-50%);

  &:after,
  &:before {
    content: "";
    width: 3px;
    height: 60%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

class CustomCollapse extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      activeId: "",
      contentHeight: 0
    }
    this.clickHandler = this.clickHandler.bind(this);
  }
  
  clickHandler(e, id, type, bio) {
    console.log(bio)
    e.preventDefault();
    if (!bio) {
      return;
    }
    if (typeof bio === "undefined") {
      return true;
    }
    
    if (this.state.activeId === id) {
      this.setState({
        activeId: "",
        contentHeight: 0
      });
    } else {
      const trigger = e.currentTarget;
      // const container = trigger.parentElement;
      const content = trigger.nextSibling;
      const inner = content.children[0];
      const height = inner.offsetHeight;
      
      this.setState({
        activeId: id,
        contentHeight: height
      });
      
      const collapseItem = document.getElementById(`collapse${id}${type}`)
      const navHeight = 150;
      
      // Wait for previous to close, the scroll to
      setTimeout(() => {
        window.scrollTo({
          top: collapseItem.offsetTop - navHeight,
          behavior: "smooth"
        })
      }, 500);
      
    }
    
  }
  
  render() {
    
    const items = this.props.data
    
    const collapseItems = items.map((item, i) => {
      if (this.props.type === 'ensemble') {
        if (i % 2 === 1) {
          console.log('break')
          return (
            <React.Fragment>
              <CustomCollapseItem
                key={i}
                id={i}
                data={item.node}
                clickHandler={this.clickHandler}
                type={this.props.type}
                activeId={this.state.activeId}
                contentHeight={this.state.activeId === i ? this.state.contentHeight : 0}
                activeClass={this.state.activeId === i ? 'active' : ''}
              />
              <div className="break"/>
            </React.Fragment>
          )
        } else {
          return <CustomCollapseItem
            key={i}
            id={i}
            data={item.node}
            clickHandler={this.clickHandler}
            type={this.props.type}
            activeId={this.state.activeId}
            contentHeight={this.state.activeId === i ? this.state.contentHeight : 0}
            activeClass={this.state.activeId === i ? 'active' : ''}
          />
        }
      } else {
        return <CustomCollapseItem
          key={i}
          id={i}
          data={item.node}
          clickHandler={this.clickHandler}
          type={this.props.type}
          activeId={this.state.activeId}
          contentHeight={this.state.activeId === i ? this.state.contentHeight : 0}
          activeClass={this.state.activeId === i ? 'active' : ''}
        />
      }
    });
    
    return (
      <CollapseContainer fluid className="px-0">
        <Row className="justify-content-center w-100 mx-0">
          {collapseItems}
        </Row>
      </CollapseContainer>
    )
  }
}

export default CustomCollapse

class CustomCollapseItem extends Component {
  render() {
    return (
      <CollapseItem
        className={`${this.props.activeClass} ${this.props.type} ${!this.props.data.bioHTML && 'no-bio'}`}
        style={{marginBottom: this.props.contentHeight + "px"}}>
        <CollapseItemTrigger
          onClick={(e) => this.props.clickHandler(e, this.props.id, this.props.type, this.props.data.bioHTML)}
          onKeyPress={(e) => this.props.clickHandler(e, this.props.id, this.props.type, this.props.data.bioHTML)}
          tabIndex="0"
          className={`${this.props.type === "cast" || this.props.type === "maincast" ? "cast" : ""} ${(typeof this.props.data.bioHTML !== "undefined") && "creative"}`}
        >
          {this.props.data.bioHTML && (
            <div className={`arrowDown ${(typeof this.props.data.bioHTML === "undefined") && "d-none"}`}/>
          )}
          <div
            className={`trigger-helper ${this.props.type === "cast" || this.props.type === "maincast" ? "cast" : ""}`}>
            {(this.props.type !== "cast" && this.props.type !== "maincast") ?
              <div className="trigger-helper-inner-border">
                <div className="trigger-helper-inner-content">
                  <CollapseTriggerTitle>
                    <div className="copy">
                      <h4>{this.props.data.name}</h4>
                      <h5>{this.props.data.role}</h5>
                    </div>
                  </CollapseTriggerTitle>
                </div>
              </div>
              :
              <CollapseTriggerTitle
                className={`${this.props.type === "cast" || this.props.type === "maincast" ? "cast" : ""}`}>
                <GatsbyImage image={getImage(this.props.data.image)} alt={this.props.data.name}/>
                
                <div className="copy">
                  <h4>{this.props.data.name}</h4>
                  <h5 dangerouslySetInnerHTML={{__html: this.props.data.role}}></h5>
                </div>
              </CollapseTriggerTitle>
            }
          </div>
        </CollapseItemTrigger>
        {this.props.data.bioHTML && (
          <CollapseItemContent
          style={{height: this.props.contentHeight + "px"}}
          id={`collapse${this.props.id}${this.props.type}`}
        >
          <CollapseItemInner>
            {(this.props.type === "cast" || this.props.type === "mainCast") && (
              <>
              {this.props.data.image && (
                <img src={this.props.data.image.childImageSharp.gatsbyImageData.images.fallback.src}
                alt={this.props.data.name}
                className="d-block d-sm-none mx-auto mb-4"/>
              )}
              </>
            )
              
            }
            <h4>{this.props.data.name}</h4>
            <div className="collapse-content" dangerouslySetInnerHTML={{__html: this.props.data.bioHTML}}/>
            <CollapseClose className="c-collapse__item-content-close"
                           onClick={(e) => this.props.clickHandler(e, this.props.id, this.props.type, this.props.data.bioHTML)}>
              <span className="sr-only">Close</span>
            </CollapseClose>
          </CollapseItemInner>
        </CollapseItemContent>
        )}
      </CollapseItem>
    );
  }
}
