import React, {Component} from "react"
import {Container, Row, Col} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import iconArrow from "../../images/icons/btn-arrows.svg"

const CollapseContainer = styled(Container)`
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    margin: 0 auto;
    text-align: left;
    
    @media ${media.sm} {
        text-align: center;
    }
    
    .break {
      flex-basis: 100%;
      height: 0;
    }
`

const CollapseItem = styled.div`
    width: 100%;
    padding: 0.25rem 0;
    
    @media ${media.sm} {
        max-width: 400px;
    }

    @media ${media.md} {
        padding-left: .5rem;
        padding-right: .5rem;
        padding-top: .5rem;
        padding-bottom: 1rem;
        width: 50%;
    }

    @media ${media.md} {
        width: 33.3%;
        padding-bottom: 2rem;
    }
    
    .arrowDown {
        position: absolute;
        top: 50%;
        right: 20px;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        background: url("${iconArrow}") no-repeat center center;
        background-size: 100%;
        transition: transform 0.2s linear ;
    }
    
    &.active {
        .arrowDown {
            transform: translateY(-50%) rotate(180deg);
        }
    }
`

const CollapseItemTrigger = styled.div`
    cursor: pointer;
    position: relative;
    display: flex;
    background: linear-gradient(to bottom, ${props => props.theme.colors.red}, ${props => props.theme.colors.yellow});
    padding: 2px;

    @media ${media.md} {
        flex-wrap: wrap;
    }

    .trigger-helper {
         padding: 0;
         width: 100%;
         
         @media ${media.sm} {
            background: ${props => props.theme.colors.white};
            padding: 3px;
         }
    }
    
    .trigger-helper-inner-border {
        background: linear-gradient(to bottom, ${props => props.theme.colors.red}, ${props => props.theme.colors.yellow});
        padding: 2px;
        
        @media ${media.sm} {
            padding: 5px;
        }
    }
    
    .trigger-helper-inner-content {
         background: ${props => props.theme.colors.white};
         padding: 4px 10px;
         transition: background 0.2s linear, color 0.2s linear;
         width: 100%;
        
        @media ${media.sm} {
            padding: 4px; 
        }
         
    }
    
    &:hover {
        text-decoration: none;
        background-color: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.black};
        padding: 2px;
        
        @media ${media.sm} {
            background-color: ${props => props.theme.colors.black};
            color: ${props => props.theme.colors.black};
        }
        
        .arrowDown {
            transform: translateY(-50%) rotate(180deg);
        }
        
        .trigger-helper {
             background: ${props => props.theme.colors.white};
             padding: 4px;
             
             @media ${media.sm} {
                background: ${props => props.theme.colors.black};
             }
        }
    }
`

const CollapseTriggerTitle = styled.div`
    width: 100%;
    text-transform: uppercase;
    font-size: 1rem;
    min-height: 45px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-right: 40px;
    
    @media ${media.sm} {
        padding-right: 0;
        min-height: 60px;
    }

    span {
        transition: opacity .4s ease;
        opacity: 1;
        display: block;
        width: 100%;

        &.role {
            margin-bottom: 0;
        }
    }
`

const CollapseItemContent = styled.div`
    height: 0;
    visibility: hidden;
    overflow: hidden;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    flex-wrap: wrap;
    justify-content: center;
    transition: height .3s ease;
    background-color: ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.white};
    width: calc(100% - 30px);
    margin: 0 0 2rem;
    
    @media ${media.sm} {
        width: 100%;
        margin: 0 auto 2rem;
    }
    
    .active & {
        visibility: visible;
    }
`

const CollapseItemInner = styled.div`
    text-align: center;
    padding: 1rem 1rem 6rem;

    @media ${media.sm} {
        padding: 3rem 1.5rem 6rem;
    }

    .collapse-content {
        margin: 0 auto;
        max-width: 800px;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
`

const CollapseClose = styled.button`
    background-color: transparent;
    border: 0;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    height: 60px;
    width: 60px;
    transform: translateX(-50%);

    &:after,
    &:before {
        content: "";
        width: 3px;
        height: 60%;
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`

class CustomCollapse extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeId: "",
            contentHeight: 0
        }
        this.clickHandler = this.clickHandler.bind(this);
    }

    clickHandler(e, id, type) {

        e.preventDefault();

        if (this.state.activeId === id) {
            this.setState({
                activeId: "",
                contentHeight: 0
            });
        } else {
            const trigger = e.currentTarget;
            // const container = trigger.parentElement;
            const content = trigger.nextSibling;
            const inner = content.children[0];
            const height = inner.offsetHeight;

            this.setState({
                activeId: id,
                contentHeight: height
            });

            const collapseItem = document.getElementById(`collapse${id}${type}`)
            const navHeight = 150;

            // Wait for previous to close, the scroll to
            setTimeout(() => {
                window.scrollTo({
                    top: collapseItem.offsetTop - navHeight,
                    behavior: "smooth"
                })
            }, 500);

        }

    }

    render() {

        const items = this.props.data

        const collapseItems = items.map((item, i) => {
            return <CustomCollapseItem
                key={i}
                id={i}
                data={item.node}
                clickHandler={this.clickHandler}
                type={this.props.type}
                activeId={this.state.activeId}
                contentHeight={this.state.activeId === i ? this.state.contentHeight : 0}
                activeClass={this.state.activeId === i ? 'active' : ''}
            />
        });

        return (
            <CollapseContainer fluid>
                <Row className="justify-content-center">
                    {collapseItems}
                </Row>
            </CollapseContainer>
        )
    }
}

export default CustomCollapse

class CustomCollapseItem extends Component {
    render() {
        return (
            <CollapseItem
                className={`${this.props.activeClass} ${this.props.type}`}
                style={{marginBottom: this.props.contentHeight + "px"}}>
                <CollapseItemTrigger
                    onClick={(e) => this.props.clickHandler(e, this.props.id, this.props.type)}
                    onKeyPress={(e) => this.props.clickHandler(e, this.props.id, this.props.type)}
                    tabIndex="0"
                >
                    <div className="arrowDown"/>
                    <div className="trigger-helper">
                        <div className="trigger-helper-inner-border">
                            <div className="trigger-helper-inner-content">
                                <CollapseTriggerTitle>
                                    <span><strong>{this.props.data.name}</strong></span>
                                    <span className="role">{this.props.data.role}</span>
                                </CollapseTriggerTitle>
                            </div>
                        </div>
                    </div>
                </CollapseItemTrigger>
                <CollapseItemContent
                    style={{height: this.props.contentHeight + "px"}}
                    id={`collapse${this.props.id}${this.props.type}`}
                >
                    <CollapseItemInner>
                        <p><span><strong>{this.props.data.name}</strong></span></p>
                        <div className="collapse-content" dangerouslySetInnerHTML={{__html: this.props.data.bioHTML}}/>
                        <CollapseClose className="c-collapse__item-content-close"
                                       onClick={(e) => this.props.clickHandler(e, this.props.id, this.props.type)}>
                            <span className="sr-only">Close</span>
                        </CollapseClose>
                    </CollapseItemInner>
                </CollapseItemContent>
            </CollapseItem>
        );
    }
}
